'use client';

import type { SxProps, Theme } from '@mui/material/styles';
import type * as React from 'react';

import Box from '@mui/material/Box';

interface Props {
  height?: number;
  width?: number;
  component?: React.ElementType;
  sx?: SxProps<Theme>;
}

const Spacer = ({ height = 1, width = 0, component = 'div', sx }: Props) => {
  return (
    <Box
      component={component}
      sx={{ height: `calc(${height} * var(--mui-spacing))`, width: `calc(${width} * var(--mui-spacing))`, ...sx }}
    />
  );
};

export default Spacer;
